import React from 'react'

import Layout from '../components/layout/Layout'
import Container from '../components/layout/Container'
import Section from '../components/layout/Section'
import taken from '../images/taken.svg'

const NotFoundPage = () => (
  <Layout>
    <Section
      css={`
        text-align: center;
      `}
    >
      <Container pt={12}>
        <h1>NOT FOUND</h1>
        <p>
          The page you are looking for doesn&#39;t exist... Please use the links
          above to navigate to the page you want.
        </p>
        <img src={taken} alt="Not Found" />
      </Container>
    </Section>
  </Layout>
)

export default NotFoundPage
